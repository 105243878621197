import { render, staticRenderFns } from "./Placas.vue?vue&type=template&id=7ceb4698&scoped=true"
import script from "./Placas.vue?vue&type=script&lang=js"
export * from "./Placas.vue?vue&type=script&lang=js"
import style0 from "./Placas.vue?vue&type=style&index=0&id=7ceb4698&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ceb4698",
  null
  
)

export default component.exports