<template>
  <v-container fluid v-if="!loading">
    <div v-show="view == 'month'">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="mx-auto"
        offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <h1 class="text-center primary--text" v-bind="attrs" v-on="on">
            {{ new Date(month+'-01').toLocaleDateString('esp',{year:'numeric',month:'long'}).toLocaleUpperCase('esp') }}
          </h1>
        </template>
        <v-date-picker
          v-model="month"
          :first-day-of-week="1"
          no-title
          scrollable
          type="month"
          :max="new Date().getFullYear() + '-' + ('0' + (new Date().getMonth()+1)).slice(-2)"
          event-color="error"
          @click:month="changeMonth($event)"
        ></v-date-picker>
      </v-menu>
      <v-row>
        <v-col cols="5" class="py-0 pe-0 mt-3" style="max-height: 90vh !important; overflow: auto;">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center" rowspan="2">{{ new Date(month + '-01').toLocaleDateString('esp',{month : 'short'}).toLocaleUpperCase() }}</th>
                <th class="text-center" colspan="4">CONSUMO (KWh)</th>
                <th class="text-center" rowspan="2">AUT (%)</th>
                <th class="text-center" rowspan="2">AVG MES</th>
              </tr>
              <tr>
                <th class="text-center">PLACAS</th>
                <th class="text-center">RED</th>
                <th class="text-center">TOTAL</th>
                <th class="text-center">MÁX</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(day,i) in modelo.filter(m => m.fecha.slice(0,-3) == month)">
                <td class="text-center">{{ i+1 }}</td>
                <td class="text-center"><v-text-field @input="day.changed = true" type="number" class="noArrow centered-input" hide-details="" dense v-model="day.placas"></v-text-field></td>
                <td class="text-center"><v-text-field @input="day.changed = true" type="number" class="noArrow centered-input" hide-details="" dense v-model="day.red"></v-text-field></td>
                <td class="text-center">{{ parseFloat(parseFloat(day.placas)+parseFloat(day.red) || 0).toFixed(2).replaceAll(".",",") }}</td>
                <td class="text-center"><v-text-field @input="day.changed = true" type="number" class="noArrow centered-input" hide-details="" dense v-model="day.max"></v-text-field></td>
                <td class="text-center">{{ parseFloat(day.placas/((day.red+day.placas) || 1)*100).toFixed(2).replaceAll(".",",") }}</td>
                <td class="text-center">{{ calculateDay(i).toFixed(2).replace('.', ',') }}</td>
                <!-- <td class="text-center">{{ parseFloat(modelo.filter(m => m.fecha.slice(0,-3) == month).slice(0,i+1).reduce((acc, sum) => acc + (parseFloat(sum.placas)/parseFloat(sum.red || 1)*100), 0) / i+1).toFixed(2).replace('.', ',') }}</td> -->
              </tr>
            </tbody>
            <thead>
              <tr>
                <th class="text-center">TOT</th>
                <th class="text-center">{{ modelo.filter(m => m.fecha.slice(0,-3) == month).reduce((acc,sum) => acc += (+sum.placas || 0),0) }}</th>
                <th class="text-center">{{ modelo.filter(m => m.fecha.slice(0,-3) == month).reduce((acc,sum) => acc += (+sum.red || 0),0) }}</th>
                <th class="text-center">{{ modelo.filter(m => m.fecha.slice(0,-3) == month).reduce((acc,sum) => acc += (+sum.placas || 0)+(+sum.red || 0),0) }}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th class="text-center">AVG</th>
                <th class="text-center">{{ parseFloat(modelo.filter(m => m.fecha.slice(0,-3) == month).map(x => x.placas || 0).reduce((acc,sum) => acc += +sum,0) / (new Date(month.split('-')[0], month.split('-')[1], 0).getDate())).toFixed(2).replaceAll(".",",") }}</th>
                <th class="text-center">{{ parseFloat(modelo.filter(m => m.fecha.slice(0,-3) == month).map(x => x.red || 0).reduce((acc,sum) => acc += +sum,0) / (new Date(month.split('-')[0], month.split('-')[1], 0).getDate())).toFixed(2).replaceAll(".",",") }}</th>
                <th></th>
                <th class="text-center">{{ parseFloat(modelo.filter(m => m.fecha.slice(0,-3) == month).map(x => x.max || 0).reduce((acc,sum) => acc += +sum,0) / (new Date(month.split('-')[0], month.split('-')[1], 0).getDate())).toFixed(2).replaceAll(".",",") }}</th>
                <th class="text-center">{{ parseFloat(modelo.filter(m => m.fecha.slice(0,-3) == month).map(x => x.placas/((x.red+x.placas) || 1)*100).reduce((acc,sum) => acc += +sum,0) / (new Date(month.split('-')[0], month.split('-')[1], 0).getDate())).toFixed(2).replaceAll(".",",") }}</th>
                <th></th>
              </tr>
            </thead>
          </v-simple-table>
        </v-col>
        <v-col cols="7">
          <v-card>
            <canvas style="min-height: 400px;" id="chartConsumo"></canvas>
          </v-card>
          <v-card class="mt-5">
            <canvas style="min-height: 400px;" id="chartAverage"></canvas>
          </v-card>
        </v-col>
      </v-row>
      <ConfirmDialog ref="confirm" />
    </div>
    <div v-show="view == 'year'">
      <v-row class="justify-center align-center">
        <v-spacer></v-spacer>
        <v-btn icon fab @click="year--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
        <h1 :class="`primary--text text-center mx-auto py-3 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" style="width: 300px;font-size: xx-large;border-radius: 5px;">
          {{ year }}
        </h1>
        <v-btn :style="year != new Date().getFullYear() ? '' : 'visibility:hidden'" icon fab @click="year++"><v-icon x-large>mdi-chevron-right</v-icon></v-btn>
        <v-spacer></v-spacer>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="3"><h2 class="primary--text">COSTE FACTURA (€)</h2></th>
            <th colspan="10"></th>
          </tr>
          <tr>
            <th>ELECTRIDICAD HOTEL</th>
            <th v-for="x in meses" class="text-center">{{ x }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(months,year) in modeloYear">
            <td>{{ year }}</td>
            <td v-for="(val,i) in months.factura_eur" class="text-center"><v-text-field type="number" v-model="months.factura_eur[i]" hide-details="" @input="modeloYear[year].changed = true" dense class="centered-input noArrow"></v-text-field></td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="3"><h2 class="primary--text">CONSUMO FACTURA (KWh)</h2></th>
            <th colspan="10"></th>
          </tr>
          <tr>
            <th>ELECTRIDICAD HOTEL</th>
            <th v-for="x in meses" class="text-center">{{ x }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(months,year) in modeloYear">
            <td>{{ year }}</td>
            <td v-for="(val,i) in months.factura_kwh" class="text-center"><v-text-field type="number" v-model="months.factura_kwh[i]" hide-details="" @input="modeloYear[year].changed = true" dense class="centered-input noArrow"></v-text-field></td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="3"><h2 class="primary--text">CONSUMO FACTURA (DIF. KWh)</h2></th>
            <th colspan="10"></th>
          </tr>
          <tr>
            <th>ELECTRIDICAD HOTEL</th>
            <th v-for="x in meses" class="text-center">{{ x }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(months,year) in modeloYear">
            <td>{{ year }}</td>
            <td v-for="(val,i) in months.factura_kwh" class="text-center">{{ modeloYear[year - 1] ? Math.round(val - (modeloYear[year - 1]?.factura_kwh[i] || 0)) : '-' }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="3"><h2 class="primary--text">CONSUMO FACTURA (DIF. %)</h2></th>
            <th colspan="10"></th>
          </tr>
          <tr>
            <th>ELECTRIDICAD HOTEL</th>
            <th v-for="x in meses" class="text-center">{{ x }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(months,year) in modeloYear">
            <td>{{ year }}</td>
            <td v-for="(val,i) in months.factura_kwh" class="text-center">
              {{
                modeloYear[year - 1] ? 
                (
                modeloYear[year - 1]?.factura_kwh[i] ? 
                (Math.round(((val - modeloYear[year - 1].factura_kwh[i]) / modeloYear[year - 1].factura_kwh[i]) * 100) + ' %') : 
                'N/A'
                ) : 
                '-'
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="3"><h2 class="primary--text">{{ this.year }} VS 2022</h2></th>
            <th colspan="12"></th>
          </tr>
          <tr>
            <th>ELECTRIDICAD HOTEL</th>
            <th v-for="x in meses">{{ x }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>KWh</td>
            <td v-for="(val,i) in modeloYear[year].factura_kwh">{{ (modeloYear["2022"].factura_kwh[i] || 0) - (modeloYear[year].factura_kwh[i] || 0) }}</td>
          </tr>
          <tr>
            <td>%</td>
            <td v-for="(val,i) in modeloYear[year].factura_eur">{{ (modeloYear["2022"].factura_eur[i] || 0) - (modeloYear[year].factura_eur[i] || 0) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartAutoconsumo"></canvas>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartAutoconsumoKwh"></canvas>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartRedKwh"></canvas>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartFactura"></canvas>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartConsumoFactura"></canvas>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <canvas style="min-height: 400px;" id="yearChartAhorro"></canvas>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn :color="view == 'month' ? 'success' : 'error'" class="me-3" fab @click="view == 'month' ? view = 'year' : view = 'month'" large>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
      <v-btn color="primary" fab @click="savePlan" large>
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </div>
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <span style="flex-direction: column;margin-top: -250px;" class="d-flex align-center justify-center">
        <v-progress-circular
          size="500"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <span style="margin-top: -270px; font-size: xx-large;font-weight: bold;">
          Cargando
        </span>
      </span>
    </div>
  </v-container>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  data() {
    return {
      view: "month",
      loading: true,
      menu: false,
      chart1:null,
      chart2:null,
      chart3:null,
      chart4:null,
      chart5:null,
      chart6:null,
      chart7:null,
      chart8:null,
      meses:["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
      modelo:[
      ],
      modeloYear:[
      ],
      month: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      year: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4),
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      borderColors:[
        'rgba(54, 162, 235, 1)',
        'rgba(181, 20, 66, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(0, 0, 207, 1)',
        'rgba(64, 255, 88, 1)',
      ],
      colors:[
        'rgba(54, 162, 235, 0.2)',
        'rgba(181, 20, 66, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(0, 0, 207, 0.2)',
        'rgba(64, 255, 88, 0.2)',
      ]
    };
  },
  watch:{
    "year":{
      handler(v){
        this.loadCharts()
      }
    }
  },
  async mounted(){
    this.loading = true
    await Promise.all([
      this.getPlacas(),
      this.getPlacasYear()
    ]);
    this.loading = false
    this.loadCharts()
  },
  computed:{
    days(){
      return new Date(parseInt(this.month.split("-")[0]), parseInt(this.month.split("-")[1]),0).getDate()
    },
    yearAutoconsumo(){
      return [
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-01').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-02').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-03').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-04').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-05').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-06').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-07').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-08').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-09').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-10').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)],
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-01').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-02').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-03').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-04').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-05').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-06').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-07').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-08').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-09').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-10').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(((r.placas || 0 )/(((r.placas || 0)+(r.red || 0)) || 1))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)]
      ]
    },
    yearAutoconsumoKwh(){
      return [
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-01').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-02').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-03').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-04').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-05').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-06').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-07').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-08').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-09').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-10').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)],
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-01').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-02').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-03').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-04').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-05').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-06').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-07').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-08').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-09').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-10').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(r.placas || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)]
      ]
    },
    yearRedKwh(){
      return [
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-01').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-02').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-03').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-04').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-05').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-06').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-07').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-08').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-09').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-10').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year-1) + '-11').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)],
        [
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-01').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-02').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 28),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-03').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-04').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-05').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-06').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-07').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-08').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-09').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-10').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 30),
        Math.round(this.modelo.filter(m => m.fecha.slice(0,7) == (this.year) + '-11').map(r => Math.round(r.red || 0 )).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 31)]
      ]
    },
  },
  methods: {
    loadCharts(){
      this.$nextTick(()=>{
        var avgMes = []
        for (let i = 0; i < this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).length; i++) {
          avgMes.push(Math.round(this.calculateDay(i)))
        }
        if(this.chart1) this.chart1.destroy()
        if(this.chart2) this.chart2.destroy()
        if(this.chart3) this.chart3.destroy()
        if(this.chart4) this.chart4.destroy()
        if(this.chart5) this.chart5.destroy()
        if(this.chart6) this.chart6.destroy()
        if(this.chart7) this.chart7.destroy()
        if(this.chart8) this.chart8.destroy()

        this.chart1 = new Chart(document.getElementById("chartConsumo"), {
          type: 'line',
          data: {
            labels: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => r.fecha.slice(-2)),null],
            datasets: [
              {
                label: 'PLACAS',
                data: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => Math.round(r.placas)),null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: 'RED',
                data: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => Math.round(r.red)),null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error'],
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                type:"line",
                label: 'MÁXIMOS',
                data: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => Math.round(r.max)),null],
                backgroundColor: [
                  this.specialColor['info'],
                ],
                borderColor: [
                  this.specialColorBorder['info'],
                ],
                fill: false,
                backgroundColor: this.specialColor['info'],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'CONSUMO',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart2 = new Chart(document.getElementById("chartAverage"), {
          type: 'line',
          data: {
            labels: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => r.fecha.slice(-2)),null],
            datasets: [
              {
                label: 'AUT (%)',
                data: [null,...this.modelo.filter(m => m.fecha.slice(0,-3) == this.month).map(r => Math.round(parseFloat(r.placas/((r.red+r.placas) || 1)*100))),null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: 'AVG MES',
                data: [null,...avgMes,null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error'],
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'AVG',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart3 = new Chart(document.getElementById("yearChartAutoconsumo"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: this.year-1,
                data: [null,...this.yearAutoconsumo[0],null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.yearAutoconsumo[1],null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'AUTOCONSUMO (%)',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: true,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart4 = new Chart(document.getElementById("yearChartAutoconsumoKwh"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: this.year-1,
                data: [null,...this.yearAutoconsumoKwh[0],null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.yearAutoconsumoKwh[1],null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'AUTOCONSUMO KWh',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: true,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart5 = new Chart(document.getElementById("yearChartRedKwh"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: this.year-1,
                data: [null,...this.yearRedKwh[0],null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.yearRedKwh[1],null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'CONSUMO RED KWh',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: true,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart6 = new Chart(document.getElementById("yearChartFactura"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: this.year-1,
                data: [null,...this.modeloYear[this.year-1].factura_eur,null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.modeloYear[this.year].factura_eur,null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'COSTE FACTURA',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: true,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart7 = new Chart(document.getElementById("yearChartConsumoFactura"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: this.year-1,
                data: [null,...this.modeloYear[this.year-1].factura_kwh,null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.modeloYear[this.year].factura_kwh,null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'CONSUMO FACTURA',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: true,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
        this.chart8 = new Chart(document.getElementById("yearChartAhorro"), {
          type: 'bar',
          data: {
            labels: [null,...this.meses,null],
            datasets: [
              {
                label: "2022",
                data: [null,...this.modeloYear["2022"].factura_eur,null],
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: this.year,
                data: [null,...this.modeloYear[this.year].factura_eur,null],
                backgroundColor: [
                  this.specialColor['success'],
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                fill: false,
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                type:'line',
                label: "DIFF",
                data: [null,...Array.from({ length: Math.max(this.modeloYear["2022"].factura_eur.length, this.modeloYear[this.year].factura_eur.length) }, (_, i) => (this.modeloYear["2022"].factura_eur[i] || 0) - (this.modeloYear[this.year].factura_eur[i] || 0)),null],
                backgroundColor: [
                  this.specialColor['info'],
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                fill: false,
                borderWidth: 1,
                yAxisID: "y2",
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }, 
            ]
          },
          options: {
            maintainAspectRatio: false,
            plugins:{
              legend:{
                labels:{
                  boxWidth: 15
                }
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'AHORRO S/AÑO SIN PLACAS',
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
              },
              y2: {
                position: 'right',
                ticks: {
                  color: this.specialColorBorder['info']
                },
                grid: {
                  drawOnChartArea: false
                },
                beginAtZero: false,
              }
            },
          },
          plugins: [ChartDataLabels],
        })
      })
    },
    async changeMonth(m){
      this.menu = false
      await Promise.all([
        this.savePlan()
      ])
      this.$set(this.$data,'month',m)
      await Promise.all([
        this.getPlacas()
      ]);
      this.loadCharts()
    },
    async changeYear(y){
      await Promise.all([
        this.savePlanYear()
      ])
      this.$set(this.$data,'year',y)
      await Promise.all([
        this.getPlacasYear()
      ]);
      this.loadCharts()
    },
    calculateDay(i) {
      const percentages = this.modelo.filter(m => m.fecha.slice(0, -3) === this.month).slice(0,i+1).map(d => parseFloat(d.placas) / ((parseFloat(d.red)+parseFloat(d.placas)) || 1) * 100);
      if (percentages.length === 0) return 'N/A';
      const average = percentages.reduce((acc, val) => acc + val, 0) / percentages.length;
      return average;
    },
    async getPlacas(){
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/diario/placas`
      });
      this.modelo = data
      for (let day = 1; day <= this.days; day++) {
        const dateStr = `${this.month}-${String(day).padStart(2, '0')}`;
        const dateObj = new Date(dateStr);
        if (dateObj >= new Date()) {
            break;
        }
        const exists = this.modelo.some(item => item.fecha === dateStr);
        if (!exists) {
            this.modelo.push({
                red: 0,
                max: 0,
                fecha: dateStr,
                placas: 0
            });
        }
        this.modelo.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
      }
    },
    async getPlacasYear(){
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/diario/placas_year`
      });
      
      const result = new Map();

      data.forEach(({ year, month, factura_kwh, factura_eur }) => {
        if (!result.has(year)) {
            result.set(year, { factura_kwh: Array(12).fill(0), factura_eur: Array(12).fill(0) });
        }
        result.get(year).factura_kwh[month] = factura_kwh;
        result.get(year).factura_eur[month] = factura_eur;
      });

      this.modeloYear = Object.fromEntries([...result.entries()].sort((a, b) => a[0] - b[0]));
    },
    // async savePlan(){
    //   const confirmation =
    //     await this.$refs.confirm.open(
    //       "Guardar los cambios",
    //       "¿Guardar los cambios?",
    //       { color: "primary" }
    //     );
    //   if (!confirmation) return
    //   try {
    //     axios({
    //       method: "POST",
    //       url: `${process.env.VUE_APP_API_URL}/diario/placas`,
    //       data: this.modelo.filter(m => m.changed)
    //     });
    //     this.modelo.forEach(m => {
    //       m.changed = false
    //     })
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async savePlan(){
      var err = false
      const confirmation =
        await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Guardar los cambios?",
          { color: "primary" }
        );
      if (!confirmation) return
      try {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/diario/placas`,
          data: this.modelo.filter(m => m.changed)
        });
        this.modelo.forEach(m => {
          m.changed = false
        })
      } catch (e) {
        err = true
        console.log(e);
      }
      try {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/diario/placas_year`,
          data: Object.keys(this.modeloYear).filter(year => this.modeloYear[year].changed).reduce((obj, year) => { obj[year] = this.modeloYear[year]; return obj; }, {})
        });
        for (const year in this.modeloYear) {
          if (this.modeloYear.hasOwnProperty(year)) {
            this.modeloYear[year].changed = false;
        }
      }
      } catch (e) {
        err = true
        console.log(e);
      }
      if (err) this.$root.$emit("snack", "Error al guardar los cambios")
      else this.$root.$emit("snack", "Se han guardado los cambios correctamente")

      this.loadCharts()
    }
  },
};
</script>
<style scoped>

.centered-input >>> input {
    text-align: center;
}
.noArrow >>> input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.noArrow >>> input::-webkit-outer-spin-button,
.noArrow >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.graph {
  width: 100% !important;
}
</style>









[
    {
        "month": 2,
        "placas": null,
        "factura_eur": 12000.0,
        "red": null,
        "year": 2022,
        "factura_kwh": 64174.0
    },
    {
        "month": 7,
        "placas": null,
        "factura_eur": null, //this should be considered 0
        "red": null,
        "year": 2022,
        "factura_kwh": 63668.0
    },
    {
        "month": 7,
        "placas": null,
        "factura_eur": 1324.0,
        "red": null,
        "year": 2023,
        "factura_kwh": 49135.0
    }
]

{
  "2022" : {
    factura_kwh:[0,0,64174.0,0,0,0,0,63668.0,0,0,0,0],
    factura_eur:[0,0,12000.0,0,0,0,0,0,0,0,0,0]
  },
  "2023" : {
    factura_kwh:[0,0,0,0,0,0,0,49135.0,0,0,0,0],
    factura_eur:[0,0,0,0,0,0,0,1324.0,0,0,0,0]
  }
}